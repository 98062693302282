import {
  Box,
  Img,
  Stack,
  VStack,
  Text,
  SimpleGrid,
  Center,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import Container from "../../../components/Container";
import FullPageLoader from "../../../components/FullPageLoader";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../../constants/colors";
import { fetchTopArtisans } from "../service";
import { PROTECTED_PATHS } from "../../../app/constants";
import { dynamicPathhandler } from "../../../components/utils/dynamicPathHandler";

const AllTopArtisan = () => {
  const appTextColor = useColorModeValue(COLORS.black, COLORS.white);
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);

  const [loading, setLoading] = useState(false);
  const [topArtisans, setTopArtisans] = useState([]);
  const navigate = useNavigate();

  const { ARTISAN_PAGE } = PROTECTED_PATHS;

  const { ARTISAN } = PROTECTED_PATHS;
  const parentPath = dynamicPathhandler(ARTISAN); // Use dynamicPathhandler

  const handleArtisanClick = (artisanId) => {
    const safeArtisanId = artisanId.replaceAll("/", "-");
    navigate(`${parentPath}/${safeArtisanId}`);
  };


  useEffect(() => {
    fetchTopArtisans(setLoading, setTopArtisans);
  }, []);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack spacing="5">
      <Box shadow="sm">
        <Container>
          <Header goto={ARTISAN_PAGE} />
        </Container>
      </Box>

      <Container>
      <Text fontSize="xl" textAlign="center" color={cardBg} mb="10px">
                  All Top Artisans
                </Text>
        {topArtisans?.length > 0 ? (
          <SimpleGrid columns={[2, 3, 4]} spacing="10px" mt="10px">
            {topArtisans.map((artisan) => (
              <Box
                key={artisan.id || v4()}
                bg={"#fff"}
                borderRadius="10px"
                p="5px"
                shadow="md"
                textAlign="center"
                cursor="pointer"
                onClick={() => handleArtisanClick(artisan.artisanId)}              >
                <Img
                  src={artisan.image}
                  alt={`${artisan.name}'s profile picture`}
                  borderRadius="full"
                  boxSize="40px"
                  mx="auto"
                  mb="3px"
                />
                <Text fontSize="xs" color={COLORS.green}>
                  {artisan.name}
                </Text>
                <Text fontSize="xs" fontWeight="bold" color={COLORS.green}>
                  {artisan.skillType}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        ) : (
          <Center flexDirection="column" h="70vh" w="100%">
            <Img src="/empty.svg" alt="empty" />
            <Text
              py="5"
              fontWeight="500"
              textAlign="center"
              color={appTextColor}
            >
              No top artisans yet
            </Text>
          </Center>
        )}
      </Container>
    </Stack>
  );
};

export default AllTopArtisan;
