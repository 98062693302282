import { Box, useColorModeValue } from "@chakra-ui/react";
import { Routes, Route, Navigate } from "react-router-dom";
import UserProfileContextProvider from "../context/userProfileContext";
import Emergency from "../pages/emergency";
import { EstateDue } from "../pages/estateDue/EstateDue";
import { GuestHistory } from "../pages/guest-history/GuestHistory";
import InviteGuest from "../pages/invite-guest/InviteGuest";
import IssueReport from "../pages/issue report";
import IssueReportsHistory from "../pages/issue report/components/IssueReportsHistory";
import OneIssue from "../pages/issue report/components/OneIssue";
import { SendMessage } from "../pages/issue report/components/SendMessage";
import ViewReportDetails from "../pages/issue report/components/ViewReportDetails";
import MarketPalce from "../pages/market place";
import AllOffers from "../pages/market place/components/AllOffers";
import AllCategory from "../pages/market place/components/AllCategory";
import SameCategory from "../pages/market place/components/SameCategory";
import CategoryProducts from "../pages/market place/components/CategoryProducts";
import JointPurchaseInfo from "../pages/market place/components/JointPurchaseInfo";
import TopProductInfo from "../pages/market place/components/TopProductInfo";
import MyErrands from "../pages/my errands";
import ErrandHome from "../pages/my errands/components/ErrandHome";
import ErrandPaymentGateway from "../pages/my errands/components/ErrandPaymentGateway";
import SelectedErrands from "../pages/my errands/components/SelectedErrands";
import MyWallet from "../pages/my wallet";
import AirtelAirtimePayment from "../pages/my wallet/components/buy airtime/AirtelAirtimePayment";
import EtisalaAirtime from "../pages/my wallet/components/buy airtime/EtisalaAirtime";
import GloAirtimePayment from "../pages/my wallet/components/buy airtime/GloAirtimePayment";
import MTNAirtimePayment from "../pages/my wallet/components/buy airtime/MTNAirtimePayment";
import AirtelDataPayment from "../pages/my wallet/components/buy data/AirtelDataPayment";
import EtisalatDataPayment from "../pages/my wallet/components/buy data/EtisalatDataPayment";
import GloDataPayment from "../pages/my wallet/components/buy data/GloDataPayment";
import MTNDataPayment from "../pages/my wallet/components/buy data/MTNDataPayment";
import CableTv from "../pages/my wallet/components/pay bills/cable subscription/CableTv";
import DSTVPayment from "../pages/my wallet/components/pay bills/cable subscription/DSTVPayment";
import GOTVPayment from "../pages/my wallet/components/pay bills/cable subscription/GOTVPayment";
import StartimesPayment from "../pages/my wallet/components/pay bills/cable subscription/StartimesPayment";
import EKEDCPayment from "../pages/my wallet/components/pay bills/disco/EKEDCPayment";
import PowerAndUtility from "../pages/my wallet/components/pay bills/disco/PowerAndUtility";
import MyEstate from "../pages/my-estate/MyEstate";
import NewsEvents from "../pages/news event";
import Notification from "../pages/notification/Notification";
import Polls from "../pages/polls";
import { Vote } from "../pages/polls/components/Vote";
import PostAdvert from "../pages/post advert";
import Settings from "../pages/settings/Settings";
import Shop from "../pages/shop";
import { EditShopProduct } from "../pages/shop/components/EditShopProduct";
import { ViewOneArchievedProduct } from "../pages/shop/components/ViewOneArchievedProduct";
import { ViewOneShopProduct } from "../pages/shop/components/ViewOneShopProduct";
import SpecialGuestRequest from "../pages/special guest request";
import { AddGuest } from "../pages/special guest request/components/AddGuest";
import ContractorArtisan from "../pages/special guest request/components/ContractorArtisan";
import { EditPartyRequest } from "../pages/special guest request/components/EditPartyRequest";
import { EReceipt } from "../pages/special guest request/components/EReceipt";
import { GuestList } from "../pages/special guest request/components/GuestList";
import PartyEvent from "../pages/special guest request/components/PartyEvent";
import PartyEventRequestForm from "../pages/special guest request/components/PartyEventRequestForm";
import Vehicles from "../pages/special guest request/components/Vehicles";
import { PROTECTED_PATHS } from "./constants";
import { COLORS } from "../constants/colors";
import { ContractorList } from "../pages/special guest request/components/ContractorList";
import { CreatePolls } from "../pages/polls/components/CreatePolls";
import BillingAnalysis from "../pages/billing-analysis";
import PayBillsModal from "../pages/my wallet/components/PayBillsModal";
import AllTopProduct from "../pages/market place/components/AllTopProduct";
import { AllTransactions } from "../pages/my wallet/components/AllTransactions";
import { EventList } from "../pages/special guest request/components/EventList";
import { VehicleList } from "../pages/special guest request/components/VehicleList";
import SpecificCategory from "../pages/market place/components/SpecificProducts";
import ItemDescription from "../pages/market place/components/ItemDescription";
import Services from "../pages/my wallet/components/Services";
import { IdCard } from "../pages/idCard";
import RequestId from "../pages/idCard/components/RequestId";
import { TransactionDetails } from "../pages/my wallet/components/TransactionDetails";
import { FilteredAccountStatement } from "../pages/my wallet/components/FilteredAccountStatement";
import Chat from "../pages/chat";
import Video from "../pages/video";
import EstateBills from "../pages/my wallet/components/EstateBills";
import ArtisanPage from "../pages/artisanPage";
import AllTopArtisan from "../pages/artisanPage/components/AllTopArtisan";
import AllArtisans from "../pages/artisanPage/components/AllArtisans";
import ArtisanDetails from "../pages/artisanPage/components/ArtisanDetails";

const AuthenticatedApp = () => {
  const pageBg = useColorModeValue(COLORS.bgGrey, COLORS.darkModeBg);
  return (
    <UserProfileContextProvider>
      <Box maxW="700px" bg={pageBg} minH="100vh" mx="auto">
        <Routes>
          <Route path={PROTECTED_PATHS.MY_ESTATE} element={<MyEstate />} />
          <Route path={PROTECTED_PATHS.MY_WALLET} element={<MyWallet />} />
          <Route path={PROTECTED_PATHS.SERVICES} element={<Services />} />
          <Route
            path={PROTECTED_PATHS.ESTATE_BILLS}
            element={<EstateBills />}
          />
          <Route
            path={PROTECTED_PATHS.ALL_TRANSACTIONS}
            element={<AllTransactions />}
          />
          <Route
            path={PROTECTED_PATHS.TRANSACTION_DETAILS}
            element={<TransactionDetails />}
          />
          <Route
            path={PROTECTED_PATHS.ACCOUNT_STATEMENT}
            element={<FilteredAccountStatement />}
          />
          <Route path={PROTECTED_PATHS.CABLE_TV} element={<CableTv />} />
          <Route
            path={PROTECTED_PATHS.POWER_UTILITY}
            element={<PowerAndUtility />}
          />
          <Route
            path={PROTECTED_PATHS.DSTV_PAYMENT}
            element={<DSTVPayment />}
          />
          <Route
            path={PROTECTED_PATHS.GOTV_PAYMENT}
            element={<GOTVPayment />}
          />
          <Route
            path={PROTECTED_PATHS.STARTIMES_PAYMENT}
            element={<StartimesPayment />}
          />
          <Route
            path={PROTECTED_PATHS.EKEDC_PAYMENT}
            element={<EKEDCPayment />}
          />
          <Route
            path={PROTECTED_PATHS.MTN_AIRTIME}
            element={<MTNAirtimePayment />}
          />
          <Route
            path={PROTECTED_PATHS.GLO_AIRTIME}
            element={<GloAirtimePayment />}
          />
          <Route
            path={PROTECTED_PATHS.AIRTEL_AIRTIME}
            element={<AirtelAirtimePayment />}
          />
          <Route
            path={PROTECTED_PATHS.ETISALAT_AIRTIME}
            element={<EtisalaAirtime />}
          />
          <Route path={PROTECTED_PATHS.MTN_DATA} element={<MTNDataPayment />} />
          <Route
            path={PROTECTED_PATHS.AIRTEL_DATA}
            element={<AirtelDataPayment />}
          />
          <Route path={PROTECTED_PATHS.GLO_DATA} element={<GloDataPayment />} />
          <Route
            path={PROTECTED_PATHS.ETISALAT_DATA}
            element={<EtisalatDataPayment />}
          />
          <Route
            path={PROTECTED_PATHS.NOTIFICATIONS}
            element={<Notification />}
          />
          <Route
            path={PROTECTED_PATHS.INVITE_GUEST}
            element={<InviteGuest />}
          />
          <Route
            path={PROTECTED_PATHS.SPECIAL_GUEST_REQUEST}
            element={<SpecialGuestRequest />}
          />
          <Route path={PROTECTED_PATHS.PARTY_EVENT} element={<PartyEvent />} />
          <Route path={PROTECTED_PATHS.VEHICLES} element={<Vehicles />} />
          <Route
            path={PROTECTED_PATHS.CONTRACTOR_ARTISAN}
            element={<ContractorArtisan />}
          />
          <Route
            path={PROTECTED_PATHS.CONTRACTOR_LIST}
            element={<ContractorList />}
          />
          <Route
            path={PROTECTED_PATHS.VEHICLE_LIST}
            element={<VehicleList />}
          />
          <Route path={PROTECTED_PATHS.EVENT_LIST} element={<EventList />} />
          <Route path={PROTECTED_PATHS.ADD_GUEST} element={<AddGuest />} />
          <Route path={PROTECTED_PATHS.E_RECEIPT} element={<EReceipt />} />
          <Route
            path={PROTECTED_PATHS.EDIT_REQUEST}
            element={<EditPartyRequest />}
          />
          <Route
            path={PROTECTED_PATHS.PARTY_EVENT_REQUEST}
            element={<PartyEventRequestForm />}
          />
          <Route
            path={PROTECTED_PATHS.EVENT_GUEST_LIST}
            element={<GuestList />}
          />
          <Route
            path={PROTECTED_PATHS.MARKET_PLACE}
            element={<MarketPalce />}
          />
          <Route
            path={PROTECTED_PATHS.ALL_CATEGORY}
            element={<AllCategory />}
          />
          <Route
            path={PROTECTED_PATHS.SAME_CATEGORY}
            element={<SameCategory />}
          />
          <Route
            path={PROTECTED_PATHS.SPECIFIC_PRODUCT}
            element={<SpecificCategory />}
          />
          <Route
            path={PROTECTED_PATHS.ITEM_DESCRIPTION}
            element={<ItemDescription />}
          />
          <Route
            path={PROTECTED_PATHS.ALL_TOP_PRODUCT}
            element={<AllTopProduct />}
          />
          <Route
            path={PROTECTED_PATHS.BILLING_ANALYSIS}
            element={<BillingAnalysis />}
          />
          <Route path={PROTECTED_PATHS.EMERGENCY} element={<Emergency />} />
          <Route
            path={PROTECTED_PATHS.ISSUE_REPORT}
            element={<IssueReport />}
          />
          <Route path={PROTECTED_PATHS.ESTATE_DUE} element={<EstateDue />} />
          <Route path={PROTECTED_PATHS.SETTINGS} element={<Settings />} />
          <Route path={PROTECTED_PATHS.GUEST_LIST} element={<GuestHistory />} />
          <Route path={PROTECTED_PATHS.NEWS_EVENTS} element={<NewsEvents />} />
          <Route path={PROTECTED_PATHS.MY_ERRANDS} element={<MyErrands />} />
          <Route path={PROTECTED_PATHS.ERRAND_HOME} element={<ErrandHome />} />
          <Route
            path={PROTECTED_PATHS.SELECTED_ERRANDS}
            element={<SelectedErrands />}
          />
          <Route
            path={PROTECTED_PATHS.ERRAND_PAYMENT_GATEWAY}
            element={<ErrandPaymentGateway />}
          />
          <Route path={PROTECTED_PATHS.POST_ADVERT} element={<PostAdvert />} />
          {/* Single Item pages */}
          <Route
            path={PROTECTED_PATHS.CATEGORY}
            element={<CategoryProducts />}
          />
          <Route
            path={PROTECTED_PATHS.OFFER_JOINT_ONE}
            element={<JointPurchaseInfo />}
          />
          <Route
            path={PROTECTED_PATHS.TOP_PRODUCT_SCREEN}
            element={<TopProductInfo />}
          />
          <Route
            path={PROTECTED_PATHS.ONE_ISSUE_REPORT}
            element={<OneIssue />}
          />
          <Route
            path={PROTECTED_PATHS.VIEW_REPORT_DETAILS}
            element={<ViewReportDetails />}
          />
          {/* issue */}
          <Route
            path={PROTECTED_PATHS.ISSUE_REPORTS_HISTORY}
            element={<IssueReportsHistory />}
          />
          <Route
            path={PROTECTED_PATHS.SEND_MESSAGE_TO_ADMIN}
            element={<SendMessage />}
          />
          <Route path={PROTECTED_PATHS.ALL_OFFERS} element={<AllOffers />} />
          {/* <Route
            path={PROTECTED_PATHS.FILTER_PAGE}
            element={<FilterProductsPage />}
          /> */}
          {/* shop */}
          <Route path={PROTECTED_PATHS.SHOP} element={<Shop />} />
          <Route
            path={PROTECTED_PATHS.VIEW_ONE_SHOP_PRODUCT}
            element={<ViewOneShopProduct />}
          />
          <Route
            path={PROTECTED_PATHS.VIEW_ONE_ARCHIEVED_PRODUCT}
            element={<ViewOneArchievedProduct />}
          />
          <Route
            path={PROTECTED_PATHS.EDIT_SHOP_PRODUCT}
            element={<EditShopProduct />}
          />
          {/* polls */}
          <Route path={PROTECTED_PATHS.POLLS} element={<Polls />} />
          <Route
            path={PROTECTED_PATHS.CREATE_POLLS}
            element={<CreatePolls />}
          />
          <Route path={PROTECTED_PATHS.VOTE} element={<Vote />} />

          {/* IdCard */}
          <Route path={PROTECTED_PATHS.ID_CARD} element={<IdCard />} />
          <Route path={PROTECTED_PATHS.REQUEST_ID} element={<RequestId />} />

          {/* Chat */}
          <Route path={PROTECTED_PATHS.CHAT} element={<Chat />} />
          {/* Video */}
          <Route path={PROTECTED_PATHS.VIDEO} element={<Video />} />
          <Route path={PROTECTED_PATHS.ARTISAN_PAGE} element={<ArtisanPage />} />
          <Route path={PROTECTED_PATHS.TOP_ARTISANS} element={<AllTopArtisan />} />
          <Route path={PROTECTED_PATHS.ARTISAN} element={<ArtisanDetails />} />
          <Route path={PROTECTED_PATHS.ARTISANS_SKILL_TYPE} element={<AllArtisans />} />

          <Route
            path="/Idcard"
            element={<Navigate to={PROTECTED_PATHS.ID_CARD}  />}
          />
        
          <Route
            path="/*"
            element={<Navigate to={PROTECTED_PATHS.MY_ESTATE} replace />}
          />
          
        </Routes>
      </Box>
    </UserProfileContextProvider>
  );
};

export default AuthenticatedApp;
